<!-- This example requires Tailwind CSS v2.0+ -->
<template>
<RegNav />
<div class="m-4 p-2 mt-10 flex" style="directions: rtl">
  <nav aria-label="Progress">
    <ol role="list" class="overflow-hidden">
      <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative']">
        <template v-if="step.status === 'complete'">
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-indigo-600" aria-hidden="true" />
          <router-link :to="step.href" class="relative flex items-start group">
            <span class="h-9 flex items-center">
              <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                <CheckIcon class="w-5 h-5 text-white" aria-hidden="true" />
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col hidden md:flex">
              <span class="text-xs font-semibold tracking-wide uppercase">{{ step.name }}</span>
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </router-link>
        </template>
        <template v-else-if="step.status === 'current'" condition="step.status === 'current'">
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
          <router-link :to="step.href" class="relative flex items-start group" aria-current="step">
            <span class="h-9 flex items-center" aria-hidden="true">
              <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-indigo-600 rounded-full">
                <span class="h-2.5 w-2.5 bg-indigo-600 rounded-full" />
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col hidden md:flex">
              <span class="text-xs font-semibold tracking-wide uppercase text-indigo-600">{{ step.name }}</span>
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </router-link>
        </template>
        <template v-else>
          <div v-if="(stepIdx !== steps.length - 1)" class="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
          <router-link :to="step.href" class="relative flex items-start group">
            <span class="h-9 flex items-center" aria-hidden="true">
              <span class="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                <span class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
              </span>
            </span>
            <span class="ml-4 min-w-0 flex flex-col hidden md:flex">
              <span class="text-xs font-semibold tracking-wide uppercase text-gray-500">{{ step.name }}</span>
              <span class="text-sm text-gray-500">{{ step.description }}</span>
            </span>
          </router-link>
        </template>
      </li>
    </ol>
  </nav>

  <router-view></router-view>
</div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/solid'
import RegNav from '@/components/profile/RegNav'
//https://207.154.252.188/greenmountain/faces/views/registrationNew/curCustomerForm.xhtml

const steps = [
  { name: 'بيانات شخصية', description: 'استكملت هذه البيانات', href: {name: 'RegistrationDemographic'}, status: 'complete' },
  { name: 'بيانات وثيقة السفر', description: 'استكملت هذه البيانات', href: {name: 'RegistrationPassport'}, status: 'current'},
  { name: 'بيانات التعاقد', description: 'مطلوبة', href: {name: 'RegistrationVisa'}, status: 'upcoming' },
  // { name: 'Theme', description: 'مطلوبة', href: {name: ''}, status: 'upcoming' },
  // { name: 'Preview', description: 'مطلوبة', href: {name: ''}, status: 'upcoming' },
]

export default {
  components: {
    CheckIcon,
    RegNav,
  },
  setup() {
    return {
      steps,
    }
  },
}
</script>